import { Dialog } from '@headlessui/react'
import classNames from 'classnames'
import React, { useState } from 'react'
import * as globalStyles from '../../../styles/global.module.scss'


const MessageSentDialog = ({ open, setOpen }: { open: boolean; setOpen: any }) => {
    return (
        <Dialog
            open={open}
            onClose={() => {
                setOpen(false)
            }}
            as="div"
            className="fixed top-0 z-[2] h-full w-full overflow-auto bg-cts-blue-56"
        >
            <Dialog.Panel className="flex h-full items-center justify-center p-[16px]">
                <div
                    className="w-full max-w-[540px] bg-cts-white p-[20px] text-center rounded-[10px]"
                    style={{
                        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.3)',
                    }}
                >
                    <h4 className="mb-[32px] mt-[24px]">Thank you for your interest</h4>
                    <p className="mb-[32px] text-cts-text-1">
                        Your message has been sent.{' '}
                    </p>
                    <button
                        onClick={() => {
                            setOpen(false)
                        }}
                        className={classNames(globalStyles.btn)}
                    >
                        OK
                    </button>
                </div>
            </Dialog.Panel>
        </Dialog>
    )
}

export const WithMessageSentDialogDialog = ({ children }: React.PropsWithChildren<{}>) => {
    const [open, setOpen] = useState(false)
    return (
        <div
            onClick={(e) => {
                const closestBtnId = (e.target as HTMLAnchorElement).closest('button')?.getAttribute('id')
                if (closestBtnId === 'sentpopup') {
                    setOpen(true)
                }
            }}
        >
            <MessageSentDialog open={open} setOpen={setOpen} />
            {children}
        </div>
    )
}
