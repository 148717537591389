import React, {useState} from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import classNames from "classnames";
import { StyledTextField } from '../../../../components/shared/TextField';
import * as globalStyles from "../../../../styles/global.module.scss";
import * as pageStyles from "../../styles/contacts.module.scss";

const pattern =
    /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

const validationSchema = yup.object({
    name: yup
        .string()
        .defined()
        .min(3, 'Name should be of minimum 3 characters length' )
        .required('Name is required'),
    email: yup
        .string()
        .defined()
        .matches( pattern, { message:'Enter a valid email'})
        .required('Email is required'),
    subject: yup
        .string()
        .defined()
        .min(3, 'Message should be of minimum 3 characters length' )
        .required('Subject is required'),
    message: yup
        .string()
        .defined()
        .min(8, 'Message should be of minimum 8 characters length')
        .required('Message is required'),
});

const encode = (data: any) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}


const FormWithMaterialUI = () => {


    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            subject: '',
            message: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values,{ resetForm }) => {
            // if(formik.isValid) {

                fetch("/", {
                    method: "POST",
                    headers: { "Content-Type": "application/x-www-form-urlencoded" },
                    body: encode({ "form-name": "contact", ...values })
                })
                    .then(() => {

                        setSentData(true);
                        resetForm()
                    })
                    .catch((error) => {
                        setSentData(false)
                        console.error(error)
                    });

                // e.preventDefault();
                // alert(JSON.stringify(values, null, 2));
            }

            // }

    });

    const [sentData, setSentData] = useState(false);


    return (
        <div>
            <form name="contact" method="post" data-netlify="true" netlify-honeypot="bot-field" hidden>
                <input type="text" name="name" />
                <input type="email" name="email" />
                <input type="text" name="subject" />
                <textarea name="message"></textarea>
            </form>
            <form onSubmit={formik.handleSubmit} className={pageStyles.formPart}>
                <input type="hidden" name="form-name" value="contact" />
                <StyledTextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Your name*"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
                <StyledTextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email*"
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />
                <StyledTextField
                    fullWidth
                    id="subject"
                    name="subject"
                    label="Subject*"
                    value={formik.values.subject}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.subject && Boolean(formik.errors.subject)}
                    helperText={formik.touched.subject && formik.errors.subject}
                />
                <StyledTextField
                    fullWidth
                    id="message"
                    name="message"
                    label="Message*"
                    multiline
                    minRows={5}
                    maxRows={5}
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.message && Boolean(formik.errors.message)}
                    helperText={formik.touched.message && formik.errors.message}
                />
                <div>
                    <button
                        id={formik.isValid && formik.dirty ? 'sentpopup' : 'buttonSubmit'}
                        className={classNames(globalStyles.btn,pageStyles.btnStartSide)}
                        type={"submit"}
                        disabled={formik.isValid && !formik.dirty || !formik.isValid }
                    >SEND</button>
                </div>
            </form>
        </div>
    );
};

export default FormWithMaterialUI;
