import React from 'react'
import { SiteCredentialsData } from '../../../../data'

export function AddressCard() {
    return (
        <div
            style={{
                boxShadow: '0px 0px 12px #B0BBD6',
            }}
            className="w-[286px] bg-cts-white py-[20px] px-[30px] "
        >
            <img className="mb-[14px]" src={require('../../../../images/icons/new-logo-black.svg').default} alt="CTS Logo" />
            <hr className="mb-[20px] border-b-[2px] border-cts-blue-72" />
            <address className="not-italic">
                <div className="mb-[10px] text-cts-text-2 not-italic">{SiteCredentialsData.address}</div>
                <a href={`mailto:${SiteCredentialsData.email}`} className="inline-flex items-center text-cts-text-2 text-cts-blue-72">
                    <img src={require('./img/letter-icon.svg').default} className="mr-[10px] inline-block" alt="Letter icon" />
                    {SiteCredentialsData.email}
                </a>
            </address>
        </div>
    )
}
