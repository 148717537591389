// extracted by mini-css-extract-plugin
export var btnStartSide = "contacts-module--btnStartSide--qtQwZ";
export var contactSection = "contacts-module--contact-section--adDj9";
export var contactSectionAddress = "contacts-module--contact-section-address--TgqLT";
export var contactSectionContacts = "contacts-module--contact-section-contacts--3F+Uj";
export var contactSectionOpeningHours = "contacts-module--contact-section-opening-hours--hCUBt";
export var contactsParts = "contacts-module--contactsParts--nTinv";
export var firstPart = "contacts-module--firstPart--CawM5";
export var formPart = "contacts-module--formPart--YL4JK";
export var inputAreaWrapper = "contacts-module--inputAreaWrapper--efM-+";
export var inputWrapper = "contacts-module--inputWrapper--5Ih1P";
export var orgInfo = "contacts-module--orgInfo--U54PS";
export var orgInfoDescription = "contacts-module--orgInfoDescription--RkRrt";
export var orgInfoTitle = "contacts-module--orgInfoTitle--54WcP";
export var orgInfoWrapper = "contacts-module--orgInfoWrapper--4IOs2";