import React from 'react'
// @ts-ignore
import GoogleMapReact from 'google-map-react'

const MapMarker = ({ lat, lng }: { lat: number; lng: number }) => (
    <div
        style={{
            display: 'inline-flex',
            transform: 'translate(-50%, -100%)',
            pointerEvents: 'none',
        }}
    >
        <img src={require('./img/google-maps-marker.svg').default} alt="CTS adress map marker" />
    </div>
)

export const Map = () => {
    const defaultProps = {
        center: { lat: 35.14681445, lng: 33.35706493 },
        zoom: 10,
    }
    return (
        <GoogleMapReact
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            bootstrapURLKeys={{ key: 'AIzaSyBZFJnZ9ku9iW0PTeUAWdGl6a5rm9e7QtI' }}
        >
            <MapMarker {...defaultProps.center} />
        </GoogleMapReact>
    )
}
