import { Layout } from '../../components/layout'
import { Container } from '../../components/layout/Container/Container'
import { HeadFC } from 'gatsby'
import * as pageStyles from './styles/contacts.module.scss'
import React from 'react'
import { Map } from './components/Map/Map'
import { AddressCard } from './components/AddressCard/AddressCard'
import mainPic from './images/main-pic.svg';
import letterIcon from './images/letter.svg'
import locationIcon from './images/location.svg'
import phoneIcon from './images/phone.svg'
import timeIcon from './images/time.svg'
import SectionHero from "../../components/sections/hero";
import FormWithMaterialUI from './components/Form'
import {WithMessageSentDialogDialog} from "../../components/shared/MessageSent";

const heroSectionData = {
    title: 'Get in touch',
    subtitle: 'Contact us to learn more about our tailor-made solutions, and how we can help you thrive.',
    image: mainPic,
}


function SectionRegulation() {
    return (
        <section id='contactsForm'>
            <Container className="tablet:py-[50px]" >
                <div className="my-[50px] text-center" >
                    <h2 className="mb-[20px] tablet:mb-[50px]">Contact us</h2>
                    <p className="mx-auto max-w-[841px] text-cts-text-1">
                        For any questions, concerns, or business proposals, please fill out the form below, or reach out via phone or email.
                    </p>
                    <div className={pageStyles.contactsParts}>
                        <div className={pageStyles.firstPart}>
                            <div className={pageStyles.orgInfoWrapper}>
                                <img src={locationIcon} alt="" width={32} height={32}/>
                                <div className={pageStyles.orgInfo}>
                                    <div className={pageStyles.orgInfoTitle}>Address</div>
                                    <div className={pageStyles.orgInfoDescription}>75 Athalassas Avenue, Strovolos 2012, Nicosia, CY</div>
                                </div>
                            </div>
                            <div className={pageStyles.orgInfoWrapper}>
                                <img src={timeIcon} alt="" width={32} height={32}/>
                                <div className={pageStyles.orgInfo}>
                                    <div className={pageStyles.orgInfoTitle}>Opening hours</div>
                                    <div className={pageStyles.orgInfoDescription}>Mon-Fri, 8:00-20:00 CET</div>
                                </div>
                            </div>
                            <div className={pageStyles.orgInfoWrapper}>
                                <img src={phoneIcon} alt="" width={32} height={32}/>
                                <div className={pageStyles.orgInfo}>
                                    <div className={pageStyles.orgInfoTitle}>Phone</div>
                                    <div className={pageStyles.orgInfoDescription}>
                                        +357 22-000877 <br/>
                                        +357 22-053184 (Trading Department) </div>
                                </div>
                            </div>
                            <div className={pageStyles.orgInfoWrapper}>
                                <img src={letterIcon} alt="" width={32} height={32}/>
                                <div className={pageStyles.orgInfo}>
                                    <div className={pageStyles.orgInfoTitle}>E-mail</div>
                                    <div className={pageStyles.orgInfoDescription}><a href="mailto:info@cloud-trading.eu">info@cloud-trading.eu</a></div>
                                </div>
                            </div>
                        </div>
                        <div className={pageStyles.formPart}>

                            <FormWithMaterialUI/>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    )
}



function SectionMap() {
    return (
        <section>
            <div className="relative h-[460px] w-full">
                <Map />
                <div className="absolute top-[250px] left-[50%]  translate-x-[-50%] tablet:top-[190px] tablet:left-[calc(50%+12px)] tablet:translate-x-[0] desktop:left-[calc(50%+40px)]">
                    <AddressCard />
                </div>
            </div>
        </section>
    )
}

const ContactsPage = () => {

    return (
        <Layout>
            <WithMessageSentDialogDialog>
                <SectionHero title={heroSectionData.title} subtitle={heroSectionData.subtitle} image={heroSectionData.image}/>
                <SectionRegulation />
                <SectionMap />
            </WithMessageSentDialogDialog>
        </Layout>
    )
}

const Head: HeadFC = () => (
    <>
        <title>Get in touch</title>
        <meta
            name="description"
            content="Contact us to learn more about our tailor-made solutions, and how we can help you thrive."
        />
    </>
)

export { ContactsPage, Head }
